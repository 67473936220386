// card style
.card-style {
  padding: 30px 16px;
  background-color: #fff;
  box-shadow:0px 0px 20px rgba(0, 0, 0, 0.09607843);
  border-radius: 20px;

  &-padding {
    padding: 30px 16px;
  }

  &.stats-card {
    padding: 14px 20px;
    box-shadow: none;
    border-radius: 12px;
    text-align: start;
  }
}

// progress-bar
.progress-container {
  display: flex;
  align-items: center;

  .progress {
    height: 10px;
    background-color: rgba($color: var(--color-primary-rgb), $alpha: 0.1);

    @media only screen and (max-width: $Xlarge) {
      width: 200px !important;
    }

    &-bar {
      &.inprogress {
        background-color: var(--color-primary);
      }

      &.done {
        background-color: #02c39a;
      }
    }
  }

  .sub-details {
    display: block;
    font-size: 12px;
    color: #888888;
  }
}

// breadcrumb
.breadcrumb {
  .breadcrumb-item {
    &::before {
      display: none;
    }

    &:not(:last-of-type)::after {
      float: right;
      padding-left: 10px;
      content: "\f054";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
    }
  }
}

// video-player-container
.video-player {
  margin: 30px 0;

  &__container {
    box-shadow: inset 0px 0px 66px #00000042;
    border-radius: 20px;
    overflow: hidden;
    // max-width: 800px;
    max-height: 480px;

    .vjs-poster {
      background-size: cover;
      background-position: center;
    }

    .video-js .vjs-big-play-button {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
    }

    video {
      width: 100%;
      height: 100%;
    }
  }
}

.list-cards {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @media only screen and (max-width: $medium) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  > li {
    flex: auto;

    @media only screen and (max-width: $medium) {
      margin: 10px;
    }
  }
}

.list-progressbar-cards {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  &-state {
    width: 36px;
    height: 36px;
    background-color: rgba($color: #888888, $alpha: 0.1);
    color: #888888;
    position: relative;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    font-weight: 800;

    @media only screen and (max-width: $medium) {
      flex: none;
    }

    @media only screen and (max-width: $small) {
      margin-right: 8px;
    }
  }

  li {
    transition: all 1s ease-out;
    position: relative;
    display: flex;
    align-items: center;

    @media only screen and (max-width: $medium) {
      align-items: flex-start;
    }

    &:not(:last-of-type) {
      .border-dashed {
        height: 80%;
        position: absolute;
        width: 2px;
        top: 47%;
        background-image: linear-gradient(
            1800deg,
            transparent,
            transparent 50%,
            #fff 50%,
            #fff 100%
          ),
          linear-gradient(180deg, #888, #888, #888, #888, #888);
        background-size: 3px 20px, 100% 20px;
        border: none;
        left: 18px;

        @media only screen and (max-width: $medium) {
          top: 36px;
        }
      }
    }

    &.completed {
      transition: all 1s ease-out;

      .list-progressbar-cards-state {
        &::after {
          content: "\f00c";
          font-family: "Font Awesome 5 Free";
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #02c39a;
          border-radius: 100%;
        }
      }

      .border-dashed {
        background-image: linear-gradient(
            1800deg,
            transparent,
            transparent 50%,
            #fff 50%,
            #fff 100%
          ),
          linear-gradient(180deg, #888, #888, #888, #888, #888);
      }
    }
  }
}

// cards
.learinig-path-card {
  display: flex;
  min-width: 568px;

  @media only screen and (max-width: $medium) {
    flex-direction: column;
    max-width: 300px;
    min-width: auto;
  }

  &__img {
    margin-right: 20px;
    border-radius: 12px;
    overflow: hidden;
    display: table;

    img {
      width: 200px;
      height: 120px;

      @media only screen and (max-width: $medium) {
        width: 100%;
        max-width: 300px;
        height: 160px;
      }
    }
  }

  &__content {
    .card {
      &-title {
        color: #222222;
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: 800;

        @media only screen and (max-width: $medium) {
          margin-top: 10px;
        }
      }

      &-detail {
        direction: rtl;
        color: #888888;
        margin-bottom: 0;
        font-size: 14px;
      }

      &-date {
        color: #888888;
        margin-bottom: 0;
        font-size: 12px;
      }
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    margin-left: auto;

    @media only screen and (max-width: $medium) {
      margin-left: 0;
      margin-top: 10px;
    }

    .btn-course {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 20px;
      border-radius: 10px;
      border: 1px solid transparent;
      min-width: 160px;
      height: 40px;
      justify-content: center;

      &.start-course {
        background-color: var(--color-primary);
        color: #fff;
        border-color: var(--color-primary);
      }

      &.done-course,
      &.inprogress-course {
        background-color: #fff;
        color: var(--color-primary);
        border-color: var(--color-primary);
      }
    }
  }

  &.learinig-path-details-card {
    flex: auto;
    padding: 25px 16px;

    .list-details {
      display: flex;

      li {
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }
    }

    .learinig-path-card__btn .btn-course {
      height: 60px;
      font-size: 14px;
      padding: 18px 36px;
      align-items: center;
      font-size: 16px;
      font-weight: bold;

      &.closed-course {
        padding: 20px ;
        font-size: 14px;
        font-weight: 800;

        #en & {
          width: 280px;
          height: 80px;
        }
      }

      @media only screen and (max-width: $medium) {
        margin-top: 20px;
        width: 100%;
      }

      p {
        font-weight: 800;

        span {
          font-weight: normal;
        }
      }

      &.inprogress-course {
        background-color: var(--color-primary);
        color: #fff;
        border-color: var(--color-primary);
      }

      &.closed-course {
        background-color: rgba($color: #000000, $alpha: 0.04);
        color: #000;
        border-color: rgba($color: #000000, $alpha: 0.04);

        .icon {
          color: #bbbabf;
          font-size: 20px;
        }
      }

      &.completed-course {
        background-color: rgba($color: var(--color-primary-rgb), $alpha: 0.04);
        color: var(--color-primary);
        border-color: rgba($color: var(--color-primary-rgb), $alpha: 0.04);
      }
    }
  }

  &.small-card {
    padding: 0;
    margin-bottom: 0 !important;
    max-width: 100%;

    .learinig-path-card__img {
      img {
        width: 80px;
        height: 60px;

        @media only screen and (max-width: $medium) {
          width: 100%;
          max-width: 80px;
          height: 60px;
        }
      }
    }
  }

  &.lesson-card {
    .learinig-path-card__img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: "";
        position: absolute;
        background-color: rgba($color: #000000, $alpha: 0.2);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .play-icon {
        position: absolute;
        margin: auto;
        font-size: 30px;
        color: #fff;
      }
    }
  }
}

.admin-learinig-path-card {
  margin-bottom: 24px;
  display: flex;
  padding: 10px;
  padding-left: 20px;

  @media only screen and (max-width: $medium) {
    flex-direction: column;
    max-width: 300px;
  }

  &__img {
    margin-right: 20px;

    img {
      width: 200px;
      height: 120px;

      @media only screen and (max-width: $medium) {
        width: 100%;
        max-width: 300px;
        height: 160px;
      }
    }
  }

  &__content {
    .list-filter {
      @media only screen and (max-width: $medium) {
        margin: 0 !important;
      }
    }

    .card {
      &-title {
        color: #222222;
        font-size: 22px;
        line-height: 16px;
        font-weight: 800;

        @media only screen and (max-width: $medium) {
          font-size: 18px;
          line-height: 12px;
          font-weight: 600;
        }
      }

      &-detail {
        direction: rtl;
        color: #888888;
        margin-top: -20px;
        margin-bottom: 0px;
        font-size: 14px;

        @media only screen and (max-width: $medium) {
          margin-top: 10px;
        }
      }

      &-date {
        color: #888888;
        margin-bottom: 0;
        font-size: 12px;

        @media only screen and (max-width: $medium) {
          margin-top: 10px;
        }
      }
    }
  }
}

.course-cover-card {
  max-width: 634px;
  height: 372px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  padding: 30px;
  position: relative;
  background-color: #fff;

  &::before {
    content: "";
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 20.12%,
      rgba(0, 0, 0, 0.595833) 48.48%,
      rgba(0, 0, 0, 0.595833) 53.47%,
      rgba(0, 0, 0, 0.595833) 56.67%
    );
    top: 60%;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
  }

  &__content {
    position: relative;

    h1 {
      color: #fff;
      font-size: 30px;
      font-weight: bold;
    }

    .list-details {
      display: flex;

      li {
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
          margin-left: 10px;
        }
      }
    }
  }
}

.admin-employee-card {
  box-shadow: 0px 0px 16px #0000000f;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 12px;
}

.posts-statistics-card {
  padding: 30px;
  h1 {
    margin-bottom: 40px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    // justify-content: space-between;
    li {
      display: flex;

      @media only screen and (max-width: $medium) {
        margin-bottom: 20px;
      }

      &:first-of-type {
        width: 100%;

        @media only screen and (max-width: $medium) {
          width: auto;
        }
      }

      .icon {
        background-color: rgba($color: #bbbabf, $alpha: 0.1);
        color: #bbbabf;
        width: 40px;
        height: 40px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
      }
    }
  }
}

.posts-leader-board-card {
  padding-bottom: 10px;
  &__header {
    margin-bottom: 22px;
    padding-inline: 14px;
  }

  .leader-board-card {
    padding-block: 18px ;
  }

  .leader-board  {
    &__list {
      li {
        border-bottom: 1px solid rgba(51, 51, 51, 0.1);
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .myself-selected {
    margin-bottom: 20px;
  }
}

.post-community-card {
  &--employee {
    padding: 30px;
  }

  &__content {
    .img {
      max-width: 100%;
      border-radius: 16px;
    }
  }

  &__comment {
    .actions-list {
      margin: 30px 0 20px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li {
        display: flex;
        align-items: center;
      }
    }

    .comments-wrapper {
      .comments-list {
        li {
          margin-top: 12px;
        }
      }
    }
  }
}

.comment-user {
  display: flex;
  border-radius: 12px;
  background-color: rgba($color: #bbbabf, $alpha: 0.08);
  padding: 10px 16px;
}

.dots-tools {
  .tools {
    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-menu {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
      border: none;
      padding: 15px 20px;
      z-index: 1;

      a {
        padding: 0.25rem 0;

        &:hover,
        &:focus {
          background-color: transparent;

          span {
            color: var(--color-primary);
            background-color: transparent;
          }
        }
      }

      .dropdown-divider {
        border-color: rgba($color: #000000, $alpha: 0.1);
      }
    }
  }
}

.leader-board {
  hr {
    border-color: #888;
    margin: 0;
  }

  &-card {
    display: flex;
    align-items: center;
    position: relative;
    padding: 13px 32px;

    @media  screen and (max-width: $Xlarge) {
      padding: 13px 13px;

    }

    @media  screen and (max-width: $medium) {
      padding: 7px 2px;
    }

    .num {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.bg-grey-radius {
      &::before {
        content: "";
        position: absolute;
        left: -16px;
        right: 0;
        width: calc(100% + 16px);
        height: 100%;
        background-color: rgba($color: #bbbabf, $alpha: 0.12);
        border-radius: 0px 20px 20px 0px;
      }
    }
  }

  .myself-selected {
    &::after {
      position: absolute;
      content: "\f0d9";
      left: -5px;
      font-family: "Font Awesome 5 Free";
      font-weight: 700;
      transform: rotate(180deg);
      color: var(--color-primary);
      font-size: 25px;
    }
  }

  .leader {
    &-1 {
      .num {
        background-image: url("../../public/images/icons/gold.svg");
        background-position: left;
        background-repeat: no-repeat;
        color: #fff;
      }
    }

    &-2 {
      .num {
        background-image: url("../../public/images/icons/silver.svg");
        background-position: left;
        background-repeat: no-repeat;
        color: #fff;
      }
    }

    &-3 {
      .num {
        background-image: url("../../public/images/icons/bronze.svg");
        background-position: left;
        background-repeat: no-repeat;
        color: #fff;
      }
    }
  }
}

.home-stats-card {
  margin-bottom: 24px;
  height: 150px;
}

.total-courses-card {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  height: 150px;

  .doughnut-chart {
    margin-right: 30px;
    margin-left: 0;

    .CircularProgressbar-text {
      font-weight: 900;
    }
  }
}

.emp-welcome-card {
  margin-bottom: 24px;
  background-color: rgba($color: var(--color-primary-rgb), $alpha: 0.04);
}

.emp-score-card {
  margin-bottom: 24px;
  background-color: rgba($color: #f7b500, $alpha: 0.06);
}

// Slider
.slider-page {
  overflow: hidden;

  .swiper {
    overflow: visible;

    @media only screen and (max-width: $medium) {
      overflow: hidden;
    }

    margin-bottom: 40px;

    &-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 30px;
    }

    &-tools {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .btn {
        background: #fff;
        box-shadow: 0px 3px 20px #0000001a;
        border-radius: 12px;

        &.swiper-button-disabled {
          opacity: 0.5;
          border-color: transparent;
        }
      }
    }

    &-slide {
      // width: auto !important;
      @media only screen and (max-width: $medium) {
      }
    }
  }
}

.create-post-button {
  padding-right: 1.5rem;

  @media only screen and (max-width: $medium) {
    width: 100%;
  }
}

.learinig-path-edit-courses-empty-state {
  display: flex;
  flex-direction: column;
  margin-top: 15vh;

  .title {
    font-weight: 800;
    font-size: 20px;
    color: #888888;
  }

  .description {
    font-size: 16px;
    color: #888888;
  }
}

.add-employee-to-track-modal {
  .selected-component {
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    padding: 5px;
    cursor: pointer;
    border-radius: 20px;
    background-color: #f2f2f4;

    &__avatar {
      width: 30px;
      height: 30px;
    }

    &__name {
      color: #222222;
      font-weight: bold;
      margin-right: 8px;
      margin-bottom: 0px;
    }

    &__remove-btn {
      margin-top: 2px;
      margin-right: 12px;
      padding-left: 17px;
    }
  }
}

// Image preview
.show-image {
  position: relative;
  display: flex;
  transition: all 0.3s ease-in-out;

  &__text {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 54%;
    left: 0;
    right: 0;
    transform: translateY(-54%) scale(1.3);
    color: #fff;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    &::before {
      content: "";
      background-color: rgb(0 0 0 / 50%);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 12px;
    }

    .show-image__text {
      opacity: 1;
      visibility: visible;
      transform: translateY(-54%) scale(1);
    }
  }
}

.preview-image-container {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(21, 17, 17, 0.61);
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  transform: scale(1.3);

  &--show {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }

  &__content {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
    position: relative;
    margin: auto;
    max-width: 70%;
    margin: auto;

    @media screen and (max-width: $medium) {
      max-width: 95%;
      overflow-y: scroll;
      max-height: 73vh;
    }
  }
}

// social-media
.social-media-h-menu {
  margin-bottom: var(--spacing-section-bottom);
  margin-top: 18px;

  li {
    margin-inline-end: 27px;
    transition: 0.4s;

    &:last-child {
      margin-inline-end: 0;
    }

    &.icon {
      &:hover,
      &:focus {
        transform: scale(1.1);
      }

      &-instagram {
        &:hover {
          background: radial-gradient(
            circle at 30% 107%,
            #fdf497 0%,
            #fdf497 5%,
            #fd5949 45%,
            #d6249f 60%,
            #285aeb 90%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      &-facebook {
        &:hover,
        &:focus {
          color: #1877f2;
        }
      }

      &-twitter {
        &:hover,
        &:focus {
          color: #1da1f2;
        }
      }

      &-snapchat {
        &:hover,
        &:focus {
          color: #fbe00f;
        }
      }

      &-linkedin {
        &:hover,
        &:focus {
          color: #0072b1;
        }
      }
    }
  }
}

//discover-card
.discover-card {
  position: relative;
  box-shadow: 0px 8px 21px #0000001A;
  border-radius: 12px;

  &-col {
    padding: 15px;
  }

  &__image {
    height: 180px;
    object-fit: cover;
    object-position: 0 60%;
    border-radius: 12px 12px 0px 0px;
    margin-bottom: 16px;
  }

  .course-label {
    position: absolute;
    top: 16px;

    #en & {
      right: 20px;
    }

    #ar & {
      left: 20px;
    }
  }

  &__action {
    margin-top: 20px;
  }
}

.select-filter {
  padding: 12px 20px;
  position: relative;

  .select {
    &__value-container {
      padding: 0;
    }

    &__single-value {
      font-size: 17px;
      font-weight: 800;
      margin-top: -2px;
    }
  }
  .icon {
    display: inline-block;
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 39px;

    &__level {
      background: var(--color-primary) 0% 0% no-repeat padding-box;
      border-radius: 2px;
      opacity: 1;
      width: 7px;
      display: inline-block;
      &--weak {
        height: 9px;
      }

      &--med {
        height: 16px;
        margin-inline: 2px;
      }

      &--good {
        height: 21px;
      }
    }
  }
}
// video
// video
.video-main-container {
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  margin: 30px auto;
  @media screen and (max-width: $medium) {
    overflow: visible;
  }
  &.open-exam-card {
    @media screen and (max-width: $medium) {
      min-height: 420px;
    }

    &:before {
      position: absolute;
      content: "";
      backdrop-filter: blur(10px);
      color: rgba(130, 130, 130, 0.5);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      pointer-events: none;
    }
  }

  .above-course-exam-card {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    z-index: 4;
    padding: 0;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: $medium) {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 0;
    }
    .above-course-result {
      display: flex;
      align-items: center;
      @media screen and (max-width: $medium) {
        margin-bottom: 10px;
      }
      .icon {
        width: 33px;
        height: 33px;
        border-radius: 100%;
        background-color: #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        &.success {
          color: rgba(1, 194, 153, 1);
          background-color: rgba(1, 194, 153, 0.1);
        }
        &.fail {
          color: rgba(175, 21, 31, 1);
          background-color: rgba(175, 21, 31, 0.1);
        }
      }
    }
    &__content {
      flex: auto;
      padding: 30px;
      @media screen and (max-width: $medium) {
        padding: 10px;
      }
    }
    &__bottom {
      background-color: rgba(34, 34, 34, 0.04);
      padding: 25px 30px;
      display: flex;
      align-items: center;
      @media screen and (max-width: $medium) {
        padding: 10px;
        flex-wrap: wrap;
      }
      .btn-main-color {
        flex: auto;
      }
    }
  }
}

.state {
  &__icon {
    width: 93px;
    height: 93px;
    border-radius: 50%;
    font-size: 35px;
    text-align: center;
    margin: 0 auto 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__desc {
    margin-block: 24px 44px;
  }

  &__details {
    margin-bottom: 30px;
  }

  &__actions {
    .btn-main-color {
      font-size: 16px;
    }
  }
}

.answer-question {
  margin-bottom: 12px;

  &__answer {
    padding-inline: 20px;
  }

  &__actions {
    .form-check {
      margin-inline-start: 20px;
    }
  }
}

.admin-card-quiz {
  padding: 30px;
  border: 1px solid #70707033;
  border-radius: 12px;

  &__answers {
    margin-top: 20px;

    li {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__correct {
      color: #000;
      font-size: 16px;
      padding-block: 6px;
      border-radius: 20px;

      i {
        color: $color-success;
      }
    }
  }
}

.upload-logo {
  &__show {
    width: 131px;
    height: 131px;
    padding: 0.5rem;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      border-radius: 12px;
      height: 100%;
      object-fit: cover;
    }
  }

  &__input {
    display: none;
  }
}

.table.table-admin {
  @media only screen and (max-width: $small) {
    .tdBefore {
      display: none;
    }
  }
  thead {
    background-color: #f1f1f2;
    @media only screen and (max-width: $small) {
      display: none;
    }
    tr {
      th {
        padding: 13px 20px;
        border-color: transparent;
        @media only screen and (max-width: $small) {
          padding: 10px 0 !important;
          display: flex;
          justify-content: center;
          border: none;
        }
        &:first-child {
          #ar & {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }

          #en & {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }
        }

        &:last-child {
          #ar & {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          #en & {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 25px 20px;
        vertical-align: middle;
        @media only screen and (max-width: $small) {
          padding: 10px 0 !important;
          display: flex;
          justify-content: center;
          border: none;
        }
      }
    }
  }
}

.learining-path-slider {
  .learinig-path-card__content {
    flex: auto;
  }
}

.empty-state {
  &__icon {
    background-color: #f2f2f3;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
    font-size: 38px;
    color: #bbbabf;
  }
}
