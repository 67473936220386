// inputs
.input-text {
  word-break: break-all;
  .did-floating-label-content {
    position: relative;
    margin-bottom: 27px;
  }

  .input-hint {
    font-size: 12px;
    color: #888888;
  }

  .did-floating-label {
    color: rgba($color: #333333, $alpha: 0.5);
    font-size: 14px;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 15px;
    padding: 0 5px;
    background: #fff;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    font-weight: bold;
  }

  .did-floating-input {
    font-size: 16px;
    display: block;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    background: #fff;
    color: #333333;
    border: 1px solid rgba($color: #333333, $alpha: 0.5);
    border-radius: 12px;
    font-weight: bold;
    &.form-select {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23BBBABF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      background-size: 16px 12px;

      #ar & {
        background-position: left 0.75rem center;
      }
    }

    &:focus {
      outline: none;
      ~ .did-floating-label {
        top: -8px;
        font-size: 13px;
      }
    }

    &:not(:placeholder-shown) ~ .did-floating-label {
      top: -8px;
      font-size: 13px;
    }
  }

  .eye-password {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 20px;
    color: #999999;
  }

  .input-error {
    // color: var(--color-primary);
    color: #af151f;
    font-size: 14px;
    float: right;
  }
}

.bubble {
  position: relative;
  background: #f0f0f0;
  height: 228px;
  width: 225px;
  margin-top: -8px;
  border-radius: 2px;
}

.bubble:before {
  content: "";
  height: 230px;
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid #d9d7d7;
  bottom: 228px;
  left: 192px;
}

.ql-picker {
  width: 100px;
}
.quill-editor-field {
  .ql-snow .ql-picker-label {
    width: 124%;
    padding-right: 15px;
  }
  .ql-container {
    font-family: inherit;
    height: auto;
    font-size: 16px;
  }
  .ql-editor {
    height: 400px;
    direction: ltr;
    text-align: left;
  }
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  text-align: right;
}
// checkbox

.checkbox-main {
  margin-bottom: 20px;
  text-align: left;
  .form-check-input {
    width: 24px;
    height: 24px;
    border-radius: 8px;
    margin-right: 10px;
    cursor: pointer;
    &:checked {
      background-color: var(--color-primary);
      border-color: var(--color-primary);
    }
    &:focus {
      border-color: rgba($color: var(--color-primary-rgb), $alpha: 0.25);
      outline: 0;
      box-shadow: 0 0 0 0.25rem
        rgba($color: var(--color-primary-rgb), $alpha: 0.25);
    }

    &--radio {
      border-radius: 50%;
    }
  }

  &.radio-check-box {
    &--green {
      border: 2px solid #70707033;
      border-radius: 12px;
      min-height: 50px;
      padding-inline: 16px;
      line-height: 45px;
      .form-check-input--radio {
        margin-top: 10px;
        margin-left: 0;

        &:checked[type="radio"],
        &:focus {
          background-color: $color-success;
          border-color: $color-success;
          box-shadow: none;
          background-position: 0px 5px;
          background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' viewBox='0 0 15 14'%3e%3cpath id='check' d='M14.182,6l-7,7L4,9.818' transform='translate(-2.232 -4.232)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5'/%3e%3c/svg%3e ");
        }
      }

      &--checked {
        background-color: rgba($color-success, 0.2);
      }

      #ar & {
        padding-inline: 16px;
      }
    }
  }
}

// upload profile
.upload-profile {
  &__container {
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    .img {
      vertical-align: middle;
      width: 100%;
      height: 100%;
      border-radius: 40px;
    }
    .edit-btn {
      position: absolute;
      background: #888888;
      border: 4px solid #ffffff;
      border-radius: 40px;
      width: 30px;
      height: 30px;
      color: #fff;
      display: flex;
      font-size: 10px;
      align-items: center;
      justify-content: center;
      // right: -10px;
      bottom: 0;
    }
  }
  p {
    font-size: 14px;
    color: rgba(51, 51, 51, 0.5);
    margin-top: 6px;
  }
}

// input-search
.input-search {
  position: relative;
  min-width: 319px;
  height: 44px;
  @media only screen and (max-width: $small) {
    min-width: auto;
  }
  .input-icon {
    color: #bbbabf;
    position: absolute;
    z-index: 9;
    top: 10px;
    bottom: 0;
    left: 13px;
  }
  .form-control {
    border: 2px solid rgba($color: #0e0b1d, $alpha: 0.1);
    border-radius: 12px;
    padding-left: 40px;
    width: 100%;
    height: 100%;
    font-size: 15px;
    &::placeholder {
      color: #bbbabf;
    }
  }
}

// select
.select-with-icon {
  min-width: 190px;
  height: 44px;
  position: relative;
  @media only screen and (max-width: $small) {
    min-width: auto;
  }
  .input-text {
    color: #bbbabf;
    position: absolute;
    z-index: 9;
    top: 10px;
    bottom: 0;
    left: 13px;
  }
  .form-select {
    border-left: none;
    color: rgba(136, 136, 136, 1);
    background-image: none;
    border: 2px solid rgba($color: #0e0b1d, $alpha: 0.1);
    border-radius: 12px;
    padding-left: 40px;
    height: 100%;
  }
  .input-icon {
    color: #bbbabf;
    position: absolute;
    top: 10px;
    bottom: 0;
    right: 13px;
  }
}

// form-switch
.form-switch {
  text-align: left;
  padding-left: 0;
  padding-right: 2.5em;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  justify-content: center;
  .form-check-input {
    float: right;
    margin-right: -2.5em;
    margin-left: 0;
    width: 50px;
    height: 30px;

    &:focus {
      border-color: rgba(2, 195, 154, 1);
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(2, 195, 154, 0.25);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    }
    &:checked {
      background-color: rgba(2, 195, 154, 1);
      border-color: rgba(2, 195, 154, 1);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }
  }
}

// input group
.radio-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  li {
    &:not(:last-of-type) {
      margin-right: 10px;
    }
    @media only screen and (max-width: $small) {
      margin-bottom: 10px;
    }
  }

  .radio-custom {
    opacity: 0;
    position: absolute;
  }

  .radio-custom-label {
    position: relative;
    border: 2px solid #e2e2e2;
    border-radius: 12px;
    width: 210px;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
    .label-text {
      display: flex;
      flex-direction: column;
      margin: 0;
      p {
        margin: 0;
        font-size: 16px;
        color: #888;
        font-weight: bold;
      }
      span {
        font-size: 12px;
        color: #777777;
      }
    }
  }

  .radio-custom + .radio-custom-label:before {
    content: "";
    background: #fff;
    border: 2px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    padding: 0px;
    margin-right: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .radio-custom + .radio-custom-label:before {
    border-radius: 50%;
  }

  .radio-custom:checked + .radio-custom-label:before {
    content: "\f00c";
    font-family: "Font Awesome\ 5 Free";
    color: #fff;
    font-weight: 900;
    font-size: 13px;
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
  // checked
  .radio-custom:checked + .radio-custom-label {
    border-color: var(--color-primary);
    background: rgba($color: var(--color-primary-rgb), $alpha: 0.1019607843);
    .label-text {
      p {
        color: #222222;
      }
    }
  }
}

// file-download
.file-download {
  display: flex;
  align-items: center;

  img {
    max-width: 20px;
    max-height: 20px;
  }
}

// input-file
.input-file {
  display: flex;
  align-items: center;
  border: 2px dashed #2222221a;
  border-radius: 12px;
  height: 70px;
  padding: 20px;
  cursor: pointer;
  p {
    color: rgba($color: #333333, $alpha: 0.5);
    font-size: 16px;
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
  }
  span {
    color: #bbbabf;
    font-size: 12px;
    margin: 0;
  }
  .icon {
    width: 20px;
    margin: 0;
  }
  input {
    display: none;
  }
  .file-label {
    color: black;
    font-size: 16px;
    text-transform: none;
  }
}

// input-comment
.input-comment {
  background-color: rgba($color: #bbbabf, $alpha: 0.08);
  margin: 20px 0 12px;
  padding: 10px 16px;
  border-radius: 12px;
  display: flex;
  input {
    margin-left: 12px;
    margin-right: 12px;
    width: 100%;
    flex: auto;
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    font-size: 15px;
    &::placeholder {
      color: #bbbabf;
    }
  }
  .icon {
    transform: rotate(180deg);
  }
}

.gray-btn {
  color: white;
  background: #888888;
  border-radius: 10px;
  width: 503px;
  height: 44px;
}

// text-area-no-style
.text-area-no-style {
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 20px;
  resize: none;
  &::placeholder {
    color: #bbbabf;
  }
}

.image-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #2222221a;
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
  p {
    color: rgba($color: #333333, $alpha: 0.5);
    font-size: 16px;
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
  }
  span {
    color: #bbbabf;
    font-size: 12px;
    margin: 0;
  }
  .icon {
    width: 20px;
    margin: 0;
  }
  input {
    display: none;
  }
  &_image-options {
    top: 16px;
    left: 16px;
    &_icon {
      padding: 12px;
      margin-right: 12px;
      color: #888888;
      background-color: white;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}

.learning-path-details-card-image {
  height: 295px;
  width: 100%;
  border-radius: 20px;
  object-fit: cover;

  @media screen and (max-width: $small) {
    height: 150px;
  }
}

// questions
.radio-input-question {
  .custom {
    $radio-primary-color: #888888;
    $radio-input-border-color: rgba(112, 112, 112, 0.2);
    $radio-control-size: 20px;
    $radio-control-size-spacing: 12px;
    $radio-control-border-width: 2px;
    margin-bottom: 24px;

    &.radio > label {
      position: relative;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      padding-left: $radio-control-size + $radio-control-size-spacing;
      #ar & {
        padding-left: 0;
        padding-right: $radio-control-size + $radio-control-size-spacing;
      }
    }

    input[type="radio"] {
      position: relative;
      margin-left: -1 * ($radio-control-size + $radio-control-size-spacing/2);
      margin-right: $radio-control-size-spacing;
      cursor: pointer;
      #ar & {
        margin-left: 0;
        margin-right: 0;
        margin-right: -1 * ($radio-control-size + $radio-control-size-spacing/2);
        margin-left: $radio-control-size-spacing;
      }
      &:after {
        content: "";
        position: absolute;
        top: -0.25 * $radio-control-size;
        left: -0.5 * $radio-control-size-spacing + $radio-control-border-width;
        width: 1 * $radio-control-size;
        height: 1 * $radio-control-size;
        background: #fff;
        border: $radio-control-border-width solid $radio-input-border-color;
        cursor: pointer;
      }
    }

    input[type="radio"] {
      top: 0 * $radio-control-border-width;

      &:before {
        transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
        transform: scale(0, 0);

        content: "";
        position: absolute;
        top: 0.5 * $radio-control-border-width;
        left: 0; //-0.25 * $control-size-spacing;
        z-index: 1;
        width: 0.6 * $radio-control-size;
        height: 0.6 * $radio-control-size;
        background: $radio-primary-color;
        border-radius: 50%;
      }

      &:checked {
        &:before {
          transform: scale(1, 1);
        }
      }

      &:after {
        border-radius: 50%;
        top: -0.25 * $radio-control-size + $radio-control-border-width;
      }
    }
  }
  &.success {
    .custom input[type="radio"] {
      &:before {
        background: rgba(1, 194, 153, 1);
      }
    }
  }
  &.fail {
    .custom input[type="radio"] {
      &:before {
        background: rgba(175, 21, 31, 1);
      }
    }
  }
}
