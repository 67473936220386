.content-page {
  position: relative;
  padding: 0;

  .employee.home {
    padding: calc(70px - 38px) 1.5rem 1.5rem;
  }

  @media only screen and (max-width: $Xlarge) {
    margin-top: 70px;
  }
  &__wrapper {
    padding: 10px 20px;
  }

  .title-page {
    color: #000;
    font-size: 24px;
    font-weight: 800;
  }

  .sticky-header {
    position: sticky;
    top: 70px;
    width: 100%;
    padding-top: 20px;
    box-shadow: 0px 5px 21px #00000014;
    background-color: #fff;
    padding: 20px;
    z-index: 9;
    padding-bottom: 70px;

    @media only screen and (max-width: $Xlarge) {
      // padding-top: 100px;
      top: auto;
    }
    @media only screen and (max-width: $small) {
      padding-bottom: 0;
      position: relative;
    }
    &.header-table {
      .right-side {
        display: flex;
        .list-filter {
          display: flex;
          flex-wrap: wrap;
          li {
            flex: auto;
            @media only screen and (max-width: $medium) {
              margin-bottom: 10px;
            }
          }
        }
      }
      .left-side {
        @media only screen and (max-width: $medium) {
          margin-bottom: 10px;
        }
      }
    }
    &.padding-b-20 {
      padding-bottom: 20px;
    }
    &.no-padding-b {
      padding-bottom: 0;
    }
    .nav-tabs {
      border: none;
      .nav-link {
        border: none;
        border-bottom: 4px solid transparent;
        color: #888888;
        font-size: 14px;
        font-weight: bold;
        margin-left: 40px;
        padding: 12px 0 12px 0;
        &.active {
          border-color: var(--color-primary);
          color: var(--color-primary);
        }
      }
    }
    &.top-sticky-header {
      top: 70px;
      @media screen and (max-width: $medium) {
        top: 18px;
      }
    }
  }

  // table
  .table {
    &.fixed-header {
      margin-top: -50px;
      @media only screen and (max-width: $small) {
        margin-top: 0;
        .tdBefore {
          display: none;
        }
      }
      thead {
        position: sticky;
        top: 185px;
        width: 100%;
        z-index: 9;
        @media only screen and (max-width: $small) {
          display: none;
        }
        @media only screen and (max-width: $medium) {
          top: 200px;
        }
        tr {
          border-color: transparent;

          th {
            color: #888;
            font-size: 12px;
            padding-inline: 40px;

            @media screen and (max-width: $Xlarge) {
              padding-inline: 21px;
            }

            @media screen and (max-width: $medium) {
              padding-inline: 10px;
            }
          }
        }
      }

      tbody {
        tr {
          @media only screen and (max-width: $small) {
            border: 1px solid rgba(51, 51, 51, 0.3);
          }

          td {
            padding: 21px 40px;
            vertical-align: baseline;
            border-bottom: 1px solid rgba(51, 51, 51, 0.3);
            @media screen and (max-width: $Xlarge) {
              padding: 21px 21px;
            }

            @media screen and (max-width: $medium) {
              padding: 15px 10px;
            }

            @media only screen and (max-width: $small) {
              padding: 10px 0 !important;
              display: flex;
              justify-content: center;
              border: none;
            }

            .leader-board-card {
              padding: 0;
            }
          }
        }
      }
    }
    &-white {
      background-color: #fff;
      box-shadow: 0px 0px 30px #0000001a;
      border-radius: 20px;
      .table > thead {
        th {
          color: #888888;
          font-size: 12px;
          font-weight: bold;
          padding: 21px 40px;

          @media screen and (max-width: $medium) {
            padding: 21px 21px;
          }
        }
      }
      .tdBefore {
        @media only screen and (max-width: $small) {
          display: none;
        }
      }
      .responsiveTable {
        thead tr {
          @media only screen and (max-width: $small) {
            top: 0;
            left: 0;
            position: static;
            display: none;
          }
        }
      }
      tr {
        vertical-align: middle;
        border-color: rgba($color: #888888, $alpha: 0.2);

        td {
          @media only screen and (max-width: $small) {
            padding: 10px 0 !important;
            display: flex;
            justify-content: center;
          }
        }
        &.bg-grey {
          background-color: rgba($color: #f3f3f3, $alpha: 0.3);
        }
      }
    }
    &__content {
      .item {
        .tools {
          .dropdown-toggle::after {
            display: none;
          }
          .dropdown-menu {
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
            border: none;
            padding: 15px 20px;
            z-index: 1;
            a {
              padding: 0.25rem 0;
              &:hover,
              &:focus {
                background-color: transparent;
                span {
                  color: var(--color-primary);
                  background-color: transparent;
                }
              }
            }
            .dropdown-divider {
              border-color: rgba($color: #000000, $alpha: 0.1);
            }
          }
        }
      }
      // user
    }
  }

  .ranks {
    .table {
      &__content {
        tr {
          background-color: #fff;
          &.leader-1,
          &.leader-2,
          &.leader-3,
          &.myself-selected {
            background-color: #f3f3f3;
          }
        }
      }
    }
  }

  // pagination
  .pagination {
    display: flex;
    flex-direction: row-reverse;
    padding-block: 21px 37px;
    li {
      margin: 0 10px;
      .page-link {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        color: #888888;
        &.Previous,
        &.Next {
          box-shadow: 0px 0px 20px #00000014;
        }
      }
      .dots {
        color: #888888;
      }
      &.active,
      &:hover {
        .page-link {
          color: #ffff;
          background-color: var(--color-primary);
        }
      }
    }
  }
}
// pages
.community-page {
  @media only screen and (max-width: $medium) {
    flex-direction: column-reverse;
  }
}

.search-part {
  .input-search {
    .form-control {
      background-color: #fff;
      box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
      border: none;
    }
  }
  @media only screen and (max-width: 1180px) {
    width: 100%;
    flex: none;
  }
}

.profile-page {
  .content-header {
    h1 {
      font-size: 26px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    p {
      font-size: 16px;
      color: #888888;
      margin-bottom: 10px;
    }
  }
  .section_label {
    font-size: 22px;
    font-weight: 800;
    margin: 25px 0;
  }
  .gray_title {
    font-size: 16px;
    color: #888888;
    font-weight: 600;
  }
  .small_hint {
    font-size: 12px;
    color: #bbbabf;
  }

  &__employee {
    .sticky-header {
      padding: 20px 0;
      @media only screen and (max-width: $Xlarge) {
        top: -18px;
        position: relative;
      }
    }
    .nav-tabs-header {
      background-color: #f9f9f9;

      .nav-tabs {
        border: none;
        @media only screen and (max-width: $medium) {
          display: flex;
          flex-wrap: nowrap;
          overflow: auto;
          overflow-y: hidden;
          button {
            flex: none;
          }
        }
      }
    }

    &__content {
      background-color: #fff;
      padding: 40px 0;
    }
  }
}

.discover-courses {
  margin-top: 40px;
  .infinite-scroll-component {
    overflow: visible !important;
  }
}

.nav-tabs-header {
  border: none;
  .nav-link {
    border: none;
    border-bottom: 4px solid transparent;
    color: #888888;
    font-size: 14px;
    font-weight: bold;
    margin-left: 0;
    margin-right: 40px;
    #ar & {
      margin-left: 40px;
      margin-right: 0;
    }
    padding: 12px 0 12px 0;
    &.active {
      border-color: var(--color-primary);
      color: var(--color-primary);
      background-color: transparent;
    }
  }
}
.course-page {
  .card-course-exam {
    &__list {
      background: rgb(246 246 246 / 50%);
      border-radius: 20px;
      padding: 30px;
      margin-top: 30px;

      &__item {
        position: relative;
        margin-inline-start: 18px;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          background: var(--color-primary);
          border-radius: 50%;
          top: 8px;
          #en & {
            left: -18px;
          }
          #ar & {
            right: -18px;
          }
        }
      }
    }

    &__title {
      margin-bottom: 21px;
    }

    &__action {
      margin-top: 30px;
      padding: 16px 121px;
      @media only screen and (max-width: $small) {
        padding: 16px 60px;
      }
    }
  }
}

.content-course-exam {
  position: relative;
  .exam-progress-float {
    z-index: 6;
    position: fixed;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 0px 30px #0000001a;
    border-radius: 0px 55px 55px 0px;
    padding: 15px;
    display: flex;
    align-items: center;
    #en & {
      right: 0;
      left: auto;
      border-radius: 55px 0px 0px 55px;
    }
  }
}
.employee-department,
.edit-learning-path {
  .remove-btn {
    height: 50px;
    padding: 12px 14px;
  }

  .input-text {
    .did-floating {
      &-label {
        color: #bbbabf;
        font-size: 14px;
      }

      &-input {
        border: 2px solid rgba(112, 112, 112, 20%);
      }
    }
  }
}

//Change password page
.change-password-page {
  padding-top: 100px;

  @media screen and (max-width: $medium) {
    padding-top: 0px;
  }

  &__current-password {
    p {
      margin-bottom: 30px;
    }
  }

  &__new-password {
    margin-top: 40px;

    p {
      margin-bottom: 30px;
    }
  }

  &__icon {
    img {
      object-fit: contain;
    }
  }
}

.site-administration {
  .input-icon {
    z-index: 1;
  }
}
