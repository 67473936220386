// media Query Sizes
$small: 768px;
$medium: 991px;
$large: 1024px;
$Xlarge: 1300px;

// fonts
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@400;700;800&display=swap");

$Almarai: "Almarai", sans-serif;

// Colors
$color-white: rgba(255, 255, 255, 1);
$color-light-red: rgba(175, 21, 31, 0);
$color-pink: rgba(175, 21, 31, 0.08);

$color-success: #36ba8e;
:root{
--color-primary: #af151f;
--color-primary-rgb: 175,21 ,31 ;

}
// Main
body {
  font-family: $Almarai;
  padding: 0 !important;
  #maincontent {
    direction: ltr;
  }
  .p-4 {
    @media only screen and (max-width: $medium) {
      padding: 10px !important;
    }
  }
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
}
.flex-0 {
  flex: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  border: none;
  background: transparent;

  &:focus,
  &:hover {
    outline: none;
  }
}

// text sizes
.font {
  &_10 {
    font-size: 10px;
  }

  &_12 {
    font-size: 12px;
  }

  &_13 {
    font-size: 13px;
  }

  &_14 {
    font-size: 14px;
  }

  &_15 {
    font-size: 15px;
  }

  &_16 {
    font-size: 16px;
  }

  &_18 {
    font-size: 18px;
  }

  &_20 {
    font-size: 20px;
  }

  &_22 {
    font-size: 22px;
  }

  &_24 {
    font-size: 24px;
  }

  &_30 {
    font-size: 30px;
  }

  &_31 {
    font-size: 31px;
  }

  &_39 {
    font-size: 39px;
  }

  &-bold {
    font-weight: bold !important;
  }
  &-extrabold {
    font-weight: 800 !important;
  }
  &-regular {
    font-weight: normal !important;
  }
  &-color {
    &-white {
      color: #fff;
    }
    &-grey {
      color: rgba(136, 136, 136, 1);
    }
    &-dark {
      color: #222;
    }
    &-grey-light {
      color: #bbbabf;
    }
    &-red {
      color: var(--color-primary);
    }
    &-green {
      color: #198754;
    }
    &-gold {
      color: #f7b500;
    }
  }
}

.min-w {
  &-140 {
    width: 140px !important;
  }
}

.state {
  &-green {
    color: rgba(1, 194, 153, 1);
  }
  &-red {
    color: rgba(175, 21, 31, 1);
  }
  &-yellow {
    color: rgba(241, 174, 78, 1);
  }
}
.align-center {
  align-items: center;
}

.btn {
  &-main-color {
    background: var(--color-primary);
    box-shadow: 0px 3px 20px #0000001a;
    border-radius: 12px;
    color: #fff;
    border-color: var(--color-primary);
    font-size: 17px;
    padding: 10px 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:active,
    &:focus,
    &.active {
      background: var(--color-primary) !important;
      color: #fff !important;
      border-color: var(--color-primary) !important;
      opacity: 0.8 !important;
    }

    &.font-15 {
      font-size: 15px;
    }
  }
  &-main-color-border {
    background: transparent;
    box-shadow: 0px 3px 20px #0000001a;
    border-radius: 12px;
    color: var(--color-primary);
    border-color: var(--color-primary);
    font-size: 17px;
    padding: 10px 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:active,
    &:focus,
    &.active {
      background: transparent !important;
      color: var(--color-primary) !important;
      border-color: var(--color-primary) !important;
      opacity: 0.8 !important;
    }

    &.font-15 {
      font-size: 15px;
    }
  }

  &-grey-color {
    background: rgba($color: #000000, $alpha: 0.05);
    // box-shadow: 0px 3px 20px #0000001A;
    border-radius: 12px;
    color: #222222;
    border-color: transparent;
    font-size: 17px;
    padding: 10px 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:active,
    &:focus,
    &.active {
      background: rgba($color: #000000, $alpha: 0.05) !important;
      color: #222222 !important;
      border-color: transparent !important;
      opacity: 0.8 !important;
    }

    &.font-15 {
      font-size: 15px;
    }
  }

  &-dark-color {
    background: #888;
    // box-shadow: 0px 3px 20px #0000001A;
    border-radius: 12px;
    color: #fff;
    border-color: transparent;
    font-size: 17px;
    padding: 10px 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:active,
    &:focus,
    &.active {
      background: #888;
      color: #fff !important;
      border-color: transparent !important;
      opacity: 0.8 !important;
    }

    &.font-15 {
      font-size: 15px;
    }
  }
  &-red-light-color {
    background: rgba($color: var(--color-primary-rgb), $alpha: 0.05);
    // box-shadow: 0px 3px 20px #0000001A;
    border-radius: 12px;
    color: var(--color-primary);
    border-color: transparent;
    font-size: 17px;
    padding: 10px 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:active,
    &:focus,
    &.active {
      background: rgba($color: var(--color-primary-rgb), $alpha: 0.05) !important;
      color: var(--color-primary) !important;
      border-color: transparent !important;
      opacity: 0.8 !important;
    }

    &.font-15 {
      font-size: 15px;
    }
  }
  &.btn-icon-border {
    line-height: 33px;

    &:hover,
    &:active,
    &:focus,
    &.active {
      background: rgba($color: #000000, $alpha: 0.05) !important;
      color: #222222 !important;
      border-color: transparent !important;
      opacity: 0.8 !important;
    }
  }
}

.text-link {
  &-red {
    color: var(--color-primary);
    font-size: 17px;
    font-weight: bold;
    &:hover {
      color: var(--color-primary);
    }

    &.under-line {
      text-decoration: underline;
      display: inline-block;
      padding: 0 3px;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.label {
  border-radius: 12px;
  padding: 4px 10px;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  &-black {
    background-color: rgba($color: #000000, $alpha: 0.1);
    color: #222;
  }
  &-grey {
    background-color: rgba($color: #000000, $alpha: 0.1);
    color: #777777;
  }
  &-red {
    background-color: rgba($color: var(--color-primary-rgb), $alpha: 0.1);
    color: var(--color-primary);
  }

  &-green {
    background-color: rgba($color: $color-success, $alpha: 0.1);
    color: $color-success;
  }
  &-yellow {
    background-color: #FEF0CC;
    color: #000;
  }
}

.course-label {
  border-radius: 12px;
  padding: 4px 12px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  &-red {
    background-color: #c15258;
  }
}

.avatar-group {
  display: flex;
  flex-wrap: wrap;
  li {
    margin-left: calc(-4px);
    margin-right: calc(-4px);
    width: 30px;
    height: 30px;
    position: relative;
    img {
      border: 1px solid #dee2e6;
    }
    .avatar-group-total {
      border: 1px solid #dee2e6;
      background-color: #e6e6e6;
      width: 30px;
      height: 30px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: bold;
      color: #888;
    }
  }
}

.delete-img-icon {
  box-shadow: 0px 4px 30px #00000014;
  border-radius: 8px;
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  font-size: 15px;
}

.badge {
  padding: 5px 11px;
  font-size: 14px;
  border-radius: 10px;

  &__success {
    background-color: $color-success;
    color: #fff;

    &--light {
      background-color: rgba($color-success, 0.08);
      color: $color-success;
    }
  }

  &__danger {
    background-color: var(--color-primary);
    color: #fff;

    &--light {
      background-color: rgba(var(--color-primary), 0.08);
      color: var(--color-primary);
    }
  }
}

.btn-icon-border {
  width: 50px;
  height: 50px;
  border: 2px solid #70707033;
  font-size: 18px;
  text-align: center;
  border-radius: 12px;
  line-height: 45px;
}

.border {
  &-color {
    &-grey-light {
      border-color: #e2e2e2;
    }
  }

  &-radius {
    &-12 {
      border-radius: 12px;
    }
  }
}

.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  .disc-bounding-box .disc-loader{
    color: var(--color-primary);
  }
}
