.admin-layout {
  // .container-fluid > .row{
  //     min-height: 100vh;
  // }

  // navbar
  .navbar-main {
    background-color: #fff;
    box-shadow: 0px 5px 21px #00000014;
    padding: 0;
    z-index: 99;
    position: sticky;
    top: 0;
    @media only screen and (min-width: $small) {
    min-height: 70px;
    }
    // logo
    .logos {
      display: flex;
      li {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;
        @media only screen and (max-width: $Xlarge) {
          padding-left: 0;
          padding-right: 0;
        }
        &:not(:last-of-type) {
          &::before {
            content: "";
            height: 35px;
            width: 1px;
            background-color: rgba(51, 51, 51, 0.3);
            position: absolute;
            right: 0;
            left: auto;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
        .navbar-brand {
          margin: 0;
          padding: 0;
          .main-logo {
            max-width: 70px;
            max-height: 70px;
          }
        }
      }
    }
    // menu
    .main-menu {
      .navbar-nav {
        // margin-left: auto;
        // margin-right: auto;
        li {
          &:not(:first-of-type) {
            margin-left: 40px;
          }
          a {
            position: relative;
            font-size: 16px;
            color: #888888;
            font-weight: bold;
            &.active,
            &:hover {
              color: var(--color-primary);
              &::after {
                content: "";
                width: 100%;
                height: 5px;
                background-color: var(--color-primary);
                position: absolute;
                bottom: -15px;
                left: 0;
                right: 0;
                border-radius: 10px 10px 0px 0px;
              }
              span {
                svg {
                  path,
                  circle,
                  line {
                    stroke: var(--color-primary);
                  }
                }
              }
            }
          }
        }
      }
    }
    // setting
    .setting-menu {
      .user-info {
        padding-bottom: 1rem;
      }
      &__item {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 1.25rem;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background-color: rgba(201, 199, 199, 0.19);
        }

        &__link {
          color: #888888;
          display: block;
          width: 100%;
          white-space: nowrap;
          text-align: start;
          font-weight: bold;
          i,
          svg {
            margin-inline-end: 1.125rem;
            font-size: 1rem;
            width: 1rem;
            height: 1rem;
            color: #bbbabf;
          }
        }
      }
    }

    .notification {
      @media screen and (max-width: $small) {
        margin-inline-end: 0;
      }


      &.navbar-dropdown {
        .dropdown-menu {
          padding: 1.25rem;
          right: -40px;
          @media screen and (max-width: $Xlarge) {
            right: -65px;
            padding: 0.5rem;
          }
          &::before {
            top: -8px;
            left: auto;
            right: 40px;
          }
          &.show {
            min-width: 25rem;
            margin-top: 1rem;

            @media screen and (max-width: $medium) {
              min-width: 270px;
            }
          }
        }

        &__toggle {
          &::before {
            content: "";
            width: 8px;
            height: 8px;
            border: 2px solid #fff;
            background-color: var(--color-primary);
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            top: 2px;
          }
        }
      }

      &__item {
        box-shadow: none;
        background-color: transparent;
        border: none;
        padding: 1.125rem;
        margin-bottom: 0;

        &--new {
          background: rgb(187 186 191 / 8%);
          margin-bottom: 1rem;
        }
      }
    }

    .setting-menu {
      .dropdown-menu {
        &::before {
          top: -8px;
          left: auto;
          right: 40px;
        }
      }
      .navbar-dropdown {
        &__toggle {
          margin-inline-end: 15px;
        }
      }
    }

    .points, .lang {
      padding: 5px 18px;
      height: 40px;

      @media screen and (max-width: $medium) {
        padding: 5px 10px;
        height: 30px;
      }
    }

    @media only screen and (max-width: $Xlarge) {
      padding-left: 30px;
      position: fixed;
      width: 100%;
    }
  }

  // SideBar
  .sidebar-main {
    // height: 66vh;
    min-height: 100vh;
    position: relative;
    max-width: 260px;
    width: 260px;
    padding-left: 0;
    @media only screen and (max-width: $Xlarge) {
      display: none;
      top: 50px;
    }
    @media only screen and (max-width: $Xlarge) {
      position: fixed;
      left: -260px;
      z-index: 999;
      transition: 1s;
      height: 100%;
      top: 100px;
      bottom: 0;
      .sidebar-main-toggle {
        //    content: "\f58e";
        z-index: 999;
        display: block;
        font-weight: 900;
        position: fixed;
        left: 10px;
        top: 10px;
        width: 30px;
        font-size: 24px;
        height: 35px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .line {
          width: 20px;
          height: 3px;
          background-color: #000;
          display: block;
          margin: 2px auto;
          border-radius: 20px;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
        //    icon animation
        .hamburger {
          position: relative;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          & .line:nth-child(3) {
            width: 15px;
            margin: 2px 0;
          }
          &.is-active {
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
          }

          &:before {
            content: "";
            position: absolute;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            width: 30px;
            height: 30px;
            border: 5px solid transparent;
            top: calc(50% - 15px);
            left: calc(50% - 15px);
            border-radius: 100%;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
          }

          //   &.is-active:before{
          //     border: 5px solid #ecf0f1;
          //   }
          &.is-active .line {
            width: 20px;
          }

          &.is-active .line:nth-child(2) {
            opacity: 0;
          }

          &.is-active .line:nth-child(1) {
            -webkit-transform: translateY(7.5px);
            -ms-transform: translateY(7.5px);
            -o-transform: translateY(7.5px);
            transform: translateY(7.5px);
          }

          &.is-active .line:nth-child(3) {
            -webkit-transform: translateY(-7.5px) rotate(90deg);
            -ms-transform: translateY(-7.5px) rotate(90deg);
            -o-transform: translateY(-7.5px) rotate(90deg);
            transform: translateY(-3.5px) rotate(90deg);
          }
        }
        // End icon
      }
      &.show {
        left: 0;
        animation: left 2s;
        box-shadow: 8px 8px 20px rgba(201, 199, 199, 0.19);
        &::after {
          content: "";
          background: rgba(0, 0, 0, 0.25);
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          pointer-events: none;
        }
      }
    }
    &::before {
      content: "";
      position: absolute;
      background: #f8f8f8;
      // height: 150%;
      width: 100%;
      z-index: -1;
      top: -112px;
      bottom: 0;
      left: 0;
      @media only screen and (max-width: $Xlarge) {
        height: auto;
        box-shadow: 8px 8px 20px rgba(201, 199, 199, 0.19);
        z-index: 999;
      }
    }

    &__content {
      position: sticky;
      top: 90px;
      ul {
        min-height: 75vh;
        position: relative;
        border-bottom: 1px solid rgba($color: #888, $alpha: 0.5);
        li {
          margin-bottom: 10px;
          a {
            border-left: 8px solid transparent;
            display: flex;
            align-items: center;
            padding: 20px 15px;
            border-radius: 0px 12px 12px 0px;
            p {
              margin: 0;
              color: #888888;
              font-size: 15px;
              margin-left: 10px;
            }
            .icon {
              color: #bbbabf;
              font-size: 20px;
            }
          }

          &.active,
          &:hover {
            a {
              svg {
                path,
                circle,
                line {
                  stroke: var(--color-primary);
                }
              }
              .icon {
                color: var(--color-primary);
              }
              p {
                color: #222222;
                font-weight: bold;
              }
            }
          }
          &.active {
            position: relative;

            &::before {
              content: "";
              position: absolute;
              height: 100%;
              width: 8px;
              background-color: var(--color-primary);

              #ar & {
                right: 0;
              }

              #en & {
                left: 0;
              }

              @media screen and (max-width: $Xlarge) {
                display: none;
              }
            }

            a {
              background: rgba($color: var(--color-primary-rgb), $alpha: 0.1);
            }
          }
        }
      }
      @media only screen and (max-width: $Xlarge) {
        z-index: 999;
        position: relative;
      }
      .explore-link {
        position: absolute;
        margin: 20px 15px;
        color: #888888;
      }
    }

    .offcanvas  {
      width: 260px;
    }

    &.sidebar-mobile {
      display: none;
      @media only screen and (max-width: $Xlarge) {
        display: block;
        top: 50px;
        padding-bottom: 80px;
      }
      .sidebar-mobile__content {
        top: 0;
        overflow: auto;
        height: 100%;
        ul {
          min-height: auto;
          margin: 0 15px;
          li {
            margin-bottom: 0;
            a {
              padding: 15px 0px;
            }
          }
          li.active a,
          li:hover a {
            border-color: transparent;
            background-color: transparent;
          }
          .sidebar-profile-details {
            padding: 20px 15px;
          }
        }
      }
    }
  }

  .course-page {
    @media only screen and (max-width: $Xlarge) {
      flex-direction: column-reverse;
      margin-bottom: 40px;
    }
    .content-coure-page {
      @media only screen and (max-width: $Xlarge) {
        max-width: 100%;
        width: 100%;
      }
    }
    .course-side {
      max-width: 420px;
      width: 425px;
      background: #ffffff;
      box-shadow: 0px 0px 20px #0000001a;
      padding: 0;
      @media only screen and (max-width: $Xlarge) {
        max-width: 100%;
        width: 100%;
      }
      .sidebar-main-toggle {
        display: none;
      }
      .course-side__content {
        position: sticky;
        top: 174px;
        .accordion {
          .accordion-item {
            border: none;
            border-radius: 0;
            .accordion-button {
              &::after {
                content: "\f067";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                background-image: none;
                transition: none;
                color: rgb(136, 136, 136);
              }
              &:not(.collapsed)::after {
                content: "\f068";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                background-image: none;
                transition: none;
                color: rgb(136, 136, 136);
              }
            }
            .accordion-button {
              background-color: #fff;
              border-bottom: 1px solid rgba($color: #ddd, $alpha: 1);
              border-radius: 0;
              &-exam{
                background-color: #0000001A;
              }
              &:focus {
                outline: none;
                box-shadow: none;
              }
              &.disabled {
                &::after {
                  display: none;
                }
              }
            }
          }
          .accordion-body {
            background-color: rgba($color: #000000, $alpha: 0.04);
            padding: 10px;
            ul {
              li {
                margin-bottom: 10px;
                a {
                  padding: 12px 15px;
                  display: flex;
                  align-items: center;
                  border-radius: 10px;
                  color: #777777;
                }
                &.active,
                &:hover {
                  a {
                    background-color: rgba($color: #777777, $alpha: 0.1);
                  }
                }
                &.done {
                  .icon {
                    color: var(--color-primary);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .page-header {
    background: #ffffff;
    box-shadow: 0px 3px 26px #0000001a;
    position: sticky;
    top: 70px;
    display: flex;
    align-items: center;
    z-index: 6;
    padding: 22px 20px;
    @media only screen and (max-width: $Xlarge) {
      position: static;
      display: flex;
      flex-direction: column;
    }
    &__title {
      .breadcrumb {
        margin-bottom: 6px;
        &-item {
          color: #888888;
          &.active {
            opacity: 0.5;
            color: #bbbabf;
          }
        }
      }
    }
    &__progress {
      margin-left: auto;
      width: 50%;
      display: flex;
      @media only screen and (max-width: $Xlarge) {
        margin-left: 0;
        width: 100%;
        .ms-auto {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
        .progress-container {
          flex-direction: column;
          p {
            padding-bottom: 8px;
          }
        }
      }
    }
  }

  .head-text-title {
    p {
      margin: 0;
    }
  }
}
