.employee-profile {

  &__card {
    --spacing-section-bottom: 40px;
    --spacing-inline: 12px;
    --spacing-block: 10px;
    position: sticky;
    top: 73px;
    margin-top: 0;

    &__actions {
      margin-top: 23px;
      margin-bottom: 30px;

      .btn-dark {
        background-color: rgba(0, 0, 0, 0.05);
        border: rgba(0, 0, 0, 0.05);
        height: 44px;
        width: 44px;
        border-radius: 12px;
        margin-inline-end: var(--spacing-inline);
      }

      .btn-send-message {
        font-size: 15px;
        margin-inline-end: var(--spacing-inline);
      }
    }

    &__about-me {
      margin-bottom: var(--spacing-section-bottom);
    }

    &__skills {
      --spacing-section-bottom: 50px;
      margin-bottom: var(--spacing-section-bottom);

      h6 {
        margin-bottom: 22px;
      }

      .badge {
        background-color: rgba(0, 0, 0, 0.05);
        margin-inline-end: var(--spacing-inline);
        padding: var(--spacing-block) var(--spacing-inline);
        margin-bottom: 8px;
      }
    }
  }

  &__quick-reports {
    padding: 30px;
    ul {
      margin-top: 20px;

      .home-stats-card {
        position: relative;
        padding: 22px 16px 19px;
        height: auto;
        box-shadow: 0px 0px 20px #00000014;
        margin-block: 12px 0;
        border-radius: 16px;

        p{
          margin-bottom: 0;
          margin-top: 5px;
          word-break: break-word;
          line-height: 1;
          width: 84%;
        }

        img {
          position: absolute;
          bottom: 5px;

          #en & {
            right: 10px;
          }

          #ar & {
            left: 10px;
          }
        }
      }
    }
  }

  &__tracks {
    margin-top: 20px;
    padding: 30px;

    &.slider-page {
      .swiper {
        margin-bottom: 0;
      }
    }
  }

  &__all-posts {
    margin-top: 30px;

    &__title {
      margin-bottom: 30px;
    }
  }
}