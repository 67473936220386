.notification {
  &__not-read {
    width: 8px;
    height: 8px;
    display: inline-block;
    background: var(--color-primary);
    border-radius: 50%;
    top: 2px;
    position: absolute;
    right: 1px;
    left: 1px;
  }

  &__item {
    border: 2px solid rgba(187, 186, 191, 0.12);
    box-shadow: 0px 0px 20px #0000000F;
    border-radius: 1rem;
    margin-bottom: 1rem;
    padding: 1rem 1.25rem;

    &__icon {
      width: 2.5rem;
      height: 2.5rem;
      background: rgb(175 21 31 / 10%);
      text-align: center;
      color: var(--color-primary);
      line-height: 2.5rem;
      border-radius: 50%;
      font-size: 1.125rem;
    }

    &__info {
      padding-inline-start: 1rem;
    }

    &--new {
      background-color: rgba(187, 186, 191, 0.10);
      position: relative;
      border: 2px solid rgba(187, 186, 191, 0.08);

      &::before {
        content: "";
        width: 8px;
        height: 8px;
        background-color: var(--color-primary);
        border-radius: 50%;
        position: absolute;
        right: 8px;
        left: 8px;
        top: 35px;
      }
    }
  }
}